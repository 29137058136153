<template>
  <app-select
    filter
    :items="states"
    :label="$t('permanentDebts.repetition')"
    label-icon="icon-calendar"
    multiple
    hide-details
    dense
    solo
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'PeriodsSelect',

  computed: {
    states() {
      return Object.keys(this.$t('permanentDebts.periodOptions')).map((period) => ({
        label: this.$t(`permanentDebts.periodOptions.${period}`),
        value: period
      }));
    }
  }
};
</script>
