<template>
  <v-container fluid pa-5 pt-10>
    <v-row class="mt-5 mb-1" no-gutters>
      <v-col>
        <h3 class="font-weight-bold">
          {{ $t('permanentDebts.directDebit') }}
          {{ details.subject }}
        </h3>
      </v-col>
    </v-row>
    <v-row v-if="details.location" class="caption" dense>
      <v-col cols="auto">{{ $t('debtor') }}:</v-col>
      <v-col class="d-flex align-center">
        <app-link-btn :title="details.location.name" :to="getLocationRoute(details.location)" />
      </v-col>
    </v-row>
    <v-row v-else-if="details.debtor" class="caption" dense>
      <v-col cols="auto">{{ $t('debtor') }}:</v-col>
      <v-col class="d-flex align-center">
        <app-btn :title="details.debtor.name" :to="getDebtorRoute(details.debtor)" />
      </v-col>
    </v-row>

    <v-row class="mt-4" justify="space-between" align="stretch">
      <v-col>
        <app-status-chip :value="details.status" />
        <div class="caption text--muted">{{ $t('status') }}</div>
      </v-col>
      <v-col class="text-right">
        <h3>
          {{ details.grossAmount | formatCurrency }}
        </h3>
        <div class="caption text--muted">{{ $t('permanentDebts.grossAmount') }}</div>
      </v-col>
    </v-row>

    <v-row justify="space-between">
      <v-col>
        <h3>
          {{ details.nextExecutionDate | formatDate }}
        </h3>
        <div class="caption text--muted">{{ $t('permanentDebts.nextExecution') }}</div>
      </v-col>
      <v-col class="text-right">
        <h3>
          {{ details.period | formatPeriod }}
        </h3>
        <div class="caption text--muted">{{ $t('permanentDebts.repetition') }}</div>
      </v-col>
    </v-row>

    <app-action-buttons :actions="actions" />

    <app-tabs
      :key="details.id"
      v-model="activeTab"
      class="mt-8"
      :tabs="filterItemsWithFulfilledCondition(tabs)"
    >
      <component
        :is="filterItemsWithFulfilledCondition(tabs)[activeTab].component"
        class="mt-4"
        v-bind="tabs[activeTab].data"
      />
    </app-tabs>

    <div v-if="hasAdminView">
      <v-divider style="margin-top: 5rem" />
      <app-api-log :data="detailedItemRaw" api-name="/permanent-debt/${identifier}/show" />
    </div>
  </v-container>
</template>

<script>
import TabDetails from '@/modules/PermanentDebts/components/Details/TabDetails';
import TabInvoices from '@/modules/PermanentDebts/components/Details/TabInvoices';
import { NAMESPACE } from '@/modules/PermanentDebts/store';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'PermanentDebtDetails',

  data: () => ({
    activeTab: 0
  }),

  props: {
    details: {
      type: Object,
      required: true
    },
    permanentDebtActions: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    ...mapState(NAMESPACE, ['detailedItemRaw']),
    actions() {
      return [this.queryParam.STOP_PERMANENT_DEBT].map((query) =>
        this.permanentDebtActions.find((action) => action.param === query)
      );
    },
    tabs() {
      return [
        {
          id: 1,
          name: this.$t('permanentDebts.details'),
          component: TabDetails,
          data: { details: this.details }
        },
        {
          id: 2,
          name: this.$t('invoices'),
          component: TabInvoices,
          data: { details: this.details },
          condition: this.permissions.permanentDebtDetails.tabInvoices
        }
      ];
    }
  },

  watch: {
    'details.id': {
      immediate: true,
      handler() {
        this.activeTab = 0;
      }
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['fetchList'])
  }
};
</script>
