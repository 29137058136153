<template>
  <div>
    <app-data-table
      :items="formattedTableData"
      :total-data-length="count"
      :headers="tableHeaders"
      :loading="loadingList"
      :class="{ 'mb-12': !isMobile }"
      :error="listError"
    >
      <template #title>
        <div class="table-title__content">
          <div :class="classesTitleLeft">
            {{ $tc('permanentDebts.permanentDebtsCounted', count) }}
          </div>
        </div>
      </template>

      <template #item="{ item }">
        <app-table-row
          :item="item"
          :highlighted="isShownInDetails(item.id)"
          @click="setDetailedParams(item.id)"
        >
          <app-table-data :sorted="isSorted(0)" :header="tableHeaders[0]">
            <app-status-chip :value="item.status || 'created'" />
          </app-table-data>
          <app-table-data :sorted="isSorted(1)" :header="tableHeaders[1]">
            <template v-if="item.location">
              {{ item.location.name }}
              <div class="subtitle-1 grey--text">
                {{ item.location.customNumber || '-' }}
              </div>
            </template>
            <template v-else>-</template>
          </app-table-data>
          <app-table-data :sorted="isSorted(2)" :header="tableHeaders[2]">
            {{ item.subject || '-' }}
            <div class="subtitle-1 grey--text">{{ item.customNumber }}</div>
          </app-table-data>
          <app-table-data :sorted="isSorted(3)" :header="tableHeaders[3]">
            {{ item.startDate | formatDate }}
            <div v-if="item.endDate">{{ item.endDate | formatDate }}</div>
            <div v-else class="subtitle-1 grey--text">{{ $t('permanentDebts.ongoing') }}</div>
          </app-table-data>
          <app-table-data :sorted="isSorted(4)" :header="tableHeaders[4]">
            {{ item.nextExecutionDate | formatDate }}
            <div class="subtitle-1 grey--text">{{ item.period | formatPeriod }}</div>
          </app-table-data>
          <app-table-data :sorted="isSorted(5)" :header="tableHeaders[5]">
            <div class="semi-bold">{{ item.grossAmount | formatCurrency }}</div>
          </app-table-data>
        </app-table-row>
      </template>

      <template #no-data>
        <div v-if="empty" class="mx-4">
          <template v-if="permissions.permanentDebts.create">
            <h2 class="mb-8">{{ $t('permanentDebts.noData') }}</h2>
            <p class="mb-10" style="max-width: 600px">{{ $t('permanentDebts.noDataInfo') }}</p>
            <app-btn class="mr-5" @click="openCreatePermanentDebtDialog">
              {{ $t('permanentDebts.uploadFirst') }}
            </app-btn>
          </template>
          <p v-else>{{ $t('permanentDebts.noData') }}</p>
        </div>
        <div v-else>
          {{ $t('noMatchingResults') }}
        </div>
      </template>
    </app-data-table>

    <app-api-log v-if="hasAdminView" :data="list" api-name="/permanent-debt/list" />
  </div>
</template>

<script>
import { NAMESPACE } from '@/modules/PermanentDebts/store';
import { mapState } from 'vuex';
import formatPermanentDebtStatus from '@/helper/filter/formatPermanentDebtStatus';
import TableMixin from '@/mixins/TableMixin';
import StatusMixin from '@/mixins/StatusMixin';

export default {
  name: 'PermanentDebtsTable',

  mixins: [TableMixin, StatusMixin],

  data() {
    return {
      tableHeaders: [
        { text: this.$t('status'), value: 'statusTranslation', sortable: false },
        {
          text: this.$t('debtor'),
          subText: this.$t('debtors.customerId'),
          value: 'location',
          alignLeft: true
        },
        {
          text: this.$t('permanentDebts.subject'),
          subText: this.$t('permanentDebts.customNumber'),
          value: 'item'
        },
        {
          text: `${this.$t('permanentDebts.start')}, ${this.$t('permanentDebts.end')}`,
          value: 'periodStart',
          sortMobile: true
        },
        {
          text: this.$t('permanentDebts.nextExecution'),
          subText: this.$t('permanentDebts.repetition'),
          value: 'nextExecutionDate',
          sortable: false
        },
        {
          text: this.$t('permanentDebts.grossAmount'),
          value: 'amount',
          sortMobile: true
        }
      ],
      loading: false
    };
  },

  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    count: {
      type: Number,
      default: 0
    },
    empty: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState(NAMESPACE, ['list', 'loadingList', 'listError']),

    formattedTableData() {
      // return already formatted table data content for correct sorting
      return JSON.parse(JSON.stringify(this.tableData)).map((item) => ({
        ...item,
        statusTranslation: formatPermanentDebtStatus(item.status)
      }));
    }
  }
};
</script>
