<template>
  <filter-wrapper :has-active-filters="hasActiveFilters" @reset="resetAllFilter">
    <v-row dense>
      <v-col cols="12" sm="4" md="3" lg="2">
        <status-select v-model="statusSelection" />
      </v-col>
      <v-col cols="12" sm="4" md="3" lg="2">
        <location-select v-model="locationSelection" />
      </v-col>
      <v-col cols="12" sm="4" md="3" lg="2">
        <subject-select v-model="subjectSelection" />
      </v-col>
      <v-col cols="12" sm="4" md="3" lg="2">
        <custom-number-select v-model="customNumberSelection" />
      </v-col>
      <v-col cols="12" sm="4" md="3" lg="2">
        <period-select v-model="periodSelection" />
      </v-col>
    </v-row>

    <template :slot="isMobile ? 'active-filters' : 'default'">
      <v-row v-if="!isMobile || hasActiveFilters" dense>
        <v-col cols="12" class="pt-3 pb-4">
          <active-filter-chip
            v-for="(selection, index) in statusSelection"
            :key="selection"
            v-model="statusSelection"
            :index="index"
            icon="icon-gauge"
          >
            {{ selection | formatPermanentDebtStatus }}
          </active-filter-chip>
          <active-filter-chip
            v-for="(selection, index) in locationSelection"
            :key="selection"
            v-model="locationSelection"
            :index="index"
            icon="icon-clients"
          >
            {{ selection }}
          </active-filter-chip>
          <active-filter-chip
            v-for="(selection, index) in subjectSelection"
            :key="selection"
            v-model="subjectSelection"
            :index="index"
            icon="icon-calendar"
          >
            {{ selection }}
          </active-filter-chip>
          <active-filter-chip
            v-for="(selection, index) in customNumberSelection"
            :key="selection"
            v-model="customNumberSelection"
            :index="index"
            icon="icon-hash"
          >
            {{ selection }}
          </active-filter-chip>
          <active-filter-chip
            v-for="(selection, index) in periodSelection"
            :key="selection"
            v-model="periodSelection"
            :index="index"
            icon="icon-calendar"
          >
            {{ selection | formatPeriod }}
          </active-filter-chip>

          <app-text-btn
            v-if="hasActiveFilters && !isMobile"
            color="background-text"
            class="caption"
            title="resetFilter"
            @click="resetAllFilter"
          />
        </v-col>
      </v-row>
    </template>
  </filter-wrapper>
</template>

<script>
import FilterWrapper from '@/shared/components/FilterWrapper';
import ActiveFilterChip from '@/shared/components/ActiveFilterChip';
import ListFilterMixin from '@/mixins/ListFilterMixin';
import StatusSelect from '@/modules/PermanentDebts/components/Filter/StatusSelect';
import CustomNumberSelect from '@/modules/PermanentDebts/components/Filter/CustomNumberSelect';
import PeriodSelect from '@/modules/PermanentDebts/components/Filter/PeriodSelect';
import LocationSelect from '@/modules/PermanentDebts/components/Filter/LocationSelect';
import SubjectSelect from '@/modules/PermanentDebts/components/Filter/SubjectSelect';

export default {
  name: 'PermanentDebtsFilter',

  mixins: [ListFilterMixin],

  components: {
    FilterWrapper,
    StatusSelect,
    CustomNumberSelect,
    LocationSelect,
    PeriodSelect,
    SubjectSelect,
    ActiveFilterChip
  }
};
</script>
