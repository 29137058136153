<template>
  <app-icon-btn
    v-if="hasAdminView"
    v-bind="$attrs"
    class="copy-report-btn"
    @click="copyToClipboard"
  >
    <app-tooltip :disabled="isMobile" text="saveReportToClipboard">
      <v-icon size="20">mdi-clipboard-text-multiple-outline</v-icon>
    </app-tooltip>
  </app-icon-btn>
</template>

<script>
import formatDateTime from '@/helper/filter/formatDateTime';
import showSnackbar from '@/helper/showSnackbar';

export default {
  name: 'CopyReportBtn',

  props: {
    customData: {
      type: String,
      default: ''
    }
  },

  methods: {
    async copyToClipboard() {
      try {
        await navigator.clipboard.writeText(
          `Allgemein:
          * System: ${process.env.VUE_APP_ENV.toUpperCase()}
          * Produkt: ${this.productName}
          * Datum, Uhrzeit: ${formatDateTime(Date.now())}
          * Seite: ${this.$route.meta.title}

          Nutzerdaten:
          * KMU-Name: ${this.$auth.user().name}
          * KMU-SAP-Nr.: ${this.$auth.user().sapNumber}
          * Browser: ${navigator.userAgent}

          ${this.customData}`.replaceAll('  ', '')
        );
        showSnackbar({
          text: 'savedReportToClipboard',
          color: 'success',
          icon: '$iconCheck',
          timeout: 2000
        });
      } catch (err) {
        showSnackbar({
          text: 'saveReportFailed'
        });
      }
    }
  }
};
</script>

<style scoped>
#app-side-drawer .copy-report-btn {
  position: absolute;
  top: 9px;
  left: 48px;
  margin-left: 16px;
}

#app-filled-view-container .copy-report-btn {
  margin-right: 16px;
}
</style>
