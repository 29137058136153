<template>
  <v-container fluid class="pa-0">
    <add-btn v-if="permissions.permanentDebts.create" @click="openCreatePermanentDebtDialog" />
    <permanent-debts-filter />

    <permanent-debts-table
      :table-data="list"
      :count="count"
      :empty="empty"
      :class="{ 'content--control-width': true, 'content--shrinked': showDetailsDrawer }"
    />

    <app-side-drawer :show="showDetailsDrawer" :error="detailsError" :report-data="reportData">
      <permanent-debt-details
        v-if="fetchedDetailedItem"
        :details="detailedItem"
        :permanent-debt-actions="actions"
      />
    </app-side-drawer>

    <component
      :is="selectedAction.component"
      v-if="fetchedDetailedItem"
      v-model="showActionDialog"
      :permanent-debt="detailedItem"
      @success="reloadDetails($route.query)"
    />
  </v-container>
</template>

<script>
import ActionMixin from '@/mixins/ActionMixin';
import AddBtn from '@/shared/components/AddBtn';
import AppSideDrawer from '@/shared/components/AppSideDrawer';
import ListFilterMixin from '@/mixins/ListFilterMixin';
import PermanentDebtDetails from '@/modules/PermanentDebts/components/Details/PermanentDebtDetails';
import PermanentDebtsFilter from '@/modules/PermanentDebts/components/Filter/PermanentDebtsFilter';
import PermanentDebtsTable from '@/modules/PermanentDebts/components/PermanentDebtsTable';
import StopPermanentDebtDialog from '@/modules/PermanentDebts/components/Details/Actions/StopPermanentDebtDialog';
import { NAMESPACE } from '@/modules/PermanentDebts/store';
import { mapMutations, mapActions, mapState } from 'vuex';
import {
  PermanentDebtStatus,
  STATUS_GROUPS
} from '@/modules/PermanentDebts/statics/permanentDebtStatus';
import { QueryParam } from '@/statics/queryParam';

export default {
  name: 'PermanentDebts',

  mixins: [ActionMixin, ListFilterMixin],

  components: {
    AddBtn,
    AppSideDrawer,
    PermanentDebtDetails,
    PermanentDebtsFilter,
    PermanentDebtsTable
  },

  data: () => ({
    queryValues: [
      QueryParam.PAGE,
      QueryParam.ITEMS_PER_PAGE,
      QueryParam.SORT_BY,
      QueryParam.SORT_DESC,
      'status',
      'location',
      'customNumber',
      'subject',
      'period'
    ]
  }),

  computed: {
    ...mapState(NAMESPACE, [
      'list',
      'count',
      'empty',
      'detailedItem',
      'loadingList',
      'loadingDetails',
      'detailsError'
    ]),
    actions() {
      return [
        {
          param: this.queryParam.STOP_PERMANENT_DEBT,
          component: StopPermanentDebtDialog,
          icon: 'icon-delete-file',
          text: this.$t('permanentDebts.stop.stopPermanentDebt'),
          callback: () =>
            this.setQueryParam(this.queryParam.ACTION, this.queryParam.STOP_PERMANENT_DEBT),
          permission: this.detailedItem.status === PermanentDebtStatus.ACTIVE,
          condition: this.permissions.permanentDebtDetails.stop
        }
      ];
    },
    fetchedDetailedItem() {
      return !this.loadingList && !this.loadingDetails && !!this.detailedItem;
    },
    showDetailsDrawer() {
      return this.$route.params.id !== undefined;
    },
    reportData() {
      if (!this.detailedItem) {
        return;
      }

      return `Einzugsdaten:
        * Identifier: ${this.detailedItem.id}
        * Kunde: ${this.detailedItem.debtor.name || '-'}
        * Einzug-Nr.: ${this.detailedItem.customNumber || '-'}`;
    }
  },

  methods: {
    ...mapMutations(NAMESPACE, {
      resetDetailedItem: 'RESET_DETAILED_ITEM'
    }),

    ...mapActions(NAMESPACE, ['fetchList', 'fetchDetailedItem']),

    updateDetailedItem() {
      if (!this.$route.params.id && this.detailedItem) {
        return this.resetDetailedItem();
      }

      if (!this.$route.params.id) {
        return;
      }

      return this.fetchDetailedItem(this.$route.params.id);
    },

    loadList(query = this.defaultQuery) {
      this.fetchList({
        page: query[QueryParam.PAGE],
        rowsPerPage: query[QueryParam.ITEMS_PER_PAGE],
        filters: this.buildFilters(),
        sorting: this.buildSorting(query)
      });
    },

    buildFilters() {
      let filters = {};

      if (this.customNumberSelection.length > 0) {
        filters.customNumber = this.customNumberSelection;
      }

      if (this.statusSelection.length > 0) {
        filters.status = this.statusSelection.flatMap((type) => STATUS_GROUPS[type] ?? []);
      }

      if (this.locationSelection.length > 0) {
        filters.location = this.locationSelection;
      }

      if (this.subjectSelection.length > 0) {
        filters.item = this.subjectSelection;
      }

      if (this.periodSelection.length > 0) {
        filters.period = this.periodSelection;
      }

      return Object.keys(filters).length > 0 ? filters : undefined;
    },

    reloadDetails(query) {
      this.updateDetailedItem();
      this.loadList(query);
    }
  },

  watch: {
    '$route.params.id'() {
      this.updateDetailedItem();
    },

    '$route.query': {
      deep: true,
      handler(query) {
        this.updateTableQueries(query, this.queryValues, this.loadList);
      }
    }
  },

  created() {
    this.loadList();
    this.updateDetailedItem();

    this.$eventHub.$on('permanent-debt-created', () => this.loadList(this.$route.query));
  },

  destroyed() {
    if (this.detailedItem) {
      this.resetDetailedItem();
    }

    this.$eventHub.$off('permanent-debt-created');
  }
};
</script>
