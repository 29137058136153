<template>
  <app-select
    filter
    :items="states"
    :label="$t('status')"
    label-icon="icon-gauge"
    multiple
    hide-details
    dense
    solo
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { STATUS_GROUPS } from '@/modules/PermanentDebts/statics/permanentDebtStatus';

export default {
  name: 'StatusSelect',

  computed: {
    states() {
      return Object.keys(STATUS_GROUPS).map((status) => ({
        label: this.$t(`permanentDebts.statusOptions.${status}`),
        value: status
      }));
    }
  }
};
</script>
