<template>
  <div>
    <app-dialog
      v-model="open"
      primary-action-text="navigation.yesStop"
      secondary-action-text="navigation.noCancel"
      title="permanentDebts.stop.stopPermanentDebt"
      text="permanentDebts.stop.requestConfirmation"
      :loading="loading"
      @submit="submit"
    />

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.yesCreate"
      secondary-action-text="navigation.noBackToPermanentDebt"
      title="permanentDebts.stop.stoppedPermanentDebt"
      text="permanentDebts.stop.confirmation"
      @submit="redirectToCreatePermanentDebt"
      @close="hideAfterSuccess"
    />
  </div>
</template>

<script>
import { NAMESPACE } from '@/modules/PermanentDebts/store';
import { mapActions } from 'vuex';
import ShowDialogMixin from '@/mixins/ShowDialogMixin';

export default {
  name: 'StopPermanentDebtDialog',

  mixins: [ShowDialogMixin],

  props: {
    permanentDebt: {
      type: Object,
      required: true
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['stopPermanentDebt']),

    async submit() {
      this.loading = true;

      const { error } = await this.stopPermanentDebt(this.permanentDebt.id);

      this.loading = false;
      if (error) {
        return;
      }

      this.submitted = true;
    },

    redirectToCreatePermanentDebt() {
      this.hideAfterSuccess();
      this.setQueryParam(
        this.queryParam.CREATE_PERMANENT_DEBT,
        `${this.permanentDebt.debtor.id},${this.permanentDebt.location.id}`
      );
    }
  }
};
</script>
