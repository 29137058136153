<template>
  <app-autocomplete
    filter
    :items="items"
    :label="$t('debtor')"
    label-icon="icon-clients"
    hide-details
    multiple
    dense
    solo
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { NAMESPACE } from '@/modules/PermanentDebts/store';
import { mapActions } from 'vuex';

export default {
  name: 'LocationSelect',

  data: () => ({
    items: []
  }),

  methods: mapActions(NAMESPACE, ['fetchFilterLocations']),

  async created() {
    const { data } = await this.fetchFilterLocations();
    this.items = data;
  }
};
</script>
