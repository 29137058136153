<template>
  <v-container>
    <template v-if="details.vouchers.length > 0">
      <links-to-voucher-list :details="details" />

      <v-row class="mt-10">
        <v-col class="subtitle text-uppercase">{{ $t('debtors.lastInvoices') }}</v-col>
      </v-row>
      <v-row v-for="invoice in details.vouchers" :key="invoice.id" class="pb-4">
        <v-col cols="6" class="hide-overflow">
          <router-link :to="getVoucherRoute(invoice)">
            {{ invoice.numberToShow || $t('unprocessed.voucherNumberIsGenerated') }}
          </router-link>
        </v-col>
        <v-col cols="3" class="text-right">
          {{ invoice.created | formatDate }}
        </v-col>
        <v-col cols="3" class="text-right">
          {{ invoice.amount | formatCurrency }}
        </v-col>
      </v-row>
    </template>

    <v-row v-else>
      <v-col>{{ $t('permanentDebts.noPermanentDebtVouchers') }}</v-col>
    </v-row>
  </v-container>
</template>

<script>
import LinksToVoucherList from '@/shared/components/LinksToVoucherList';

export default {
  name: 'TabInvoices',

  components: { LinksToVoucherList },

  props: {
    details: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
