<template>
  <v-container class="px-0">
    <app-labeled-data
      text-right
      no-gutters
      label="permanentDebts.subject"
      :value="details.subject"
    />
    <app-labeled-data text-right no-gutters label="description" :value="details.description" />
    <app-labeled-data text-right no-gutters label="permanentDebts.start">
      {{ details.startDate | formatDate }}
    </app-labeled-data>
    <app-labeled-data v-if="details.stop" text-right no-gutters label="permanentDebts.stopVoucher">
      {{ details.stop.created | formatDate }}
    </app-labeled-data>
    <app-labeled-data v-else text-right no-gutters label="permanentDebts.end">
      <template v-if="details.endDate">{{ details.endDate | formatDate }}</template>
      <template v-else>{{ $t('permanentDebts.ongoing') }}</template>
    </app-labeled-data>
    <app-labeled-data text-right no-gutters label="permanentDebts.repetition">
      {{ details.period | formatPeriod }}
      <span v-if="showPerformanceDateIndex">
        {{ $t('permanentDebts.onDayOfMonth', { day: details.performanceDateIndex }) }}
      </span>
    </app-labeled-data>
    <app-labeled-data text-right no-gutters label="permanentDebts.grossAmount">
      <div class="d-flex align-center flex-nowrap">
        {{ details.grossAmount | formatCurrency }}
        <app-tooltip min-width="300">
          <template #text>
            <app-labeled-data :wrap-mobile="false" text-right label="permanentDebts.netAmount">
              {{ details.netAmount | formatCurrency }}
            </app-labeled-data>
            <app-labeled-data
              :wrap-mobile="false"
              text-right
              label="permanentDebts.vat"
              :value="`${details.vat} %`"
            />
          </template>
          <icon-info class="ml-2 grey--text" height="15px" width="15px" />
        </app-tooltip>
      </div>
    </app-labeled-data>

    <app-labeled-data
      class="mt-8"
      text-right
      no-gutters
      label="permanentDebts.customNumber"
      :value="details.customNumber"
    />

    <v-row no-gutters class="mt-10">
      <v-col class="subtitle">{{ $t('files') }}</v-col>
    </v-row>
    <app-labeled-data text-right no-gutters label="vouchers.officialVoucher">
      <file-link-btn
        v-if="details.officialVoucherSource"
        :loading="loadingFileType === 'officialVoucher'"
        :title="`${$t('vouchers.officialVoucher')}.pdf`"
        append-icon
        @click="
          openFile(
            details.officialVoucherSource,
            'officialVoucher',
            `${$t('vouchers.officialVoucher')}.pdf`
          )
        "
      />
      <span v-else>-</span>
    </app-labeled-data>
    <app-labeled-data text-right no-gutters label="attachment">
      <file-link-btn
        v-if="details.attachment"
        :loading="loadingFileType === 'attachment'"
        :title="details.attachment.fileName"
        append-icon
        @click="openFile(details.attachment.source, 'attachment', details.attachment.fileName)"
      />
      <span v-else>-</span>
    </app-labeled-data>
    <app-labeled-data v-if="details.stop" text-right no-gutters label="permanentDebts.stopVoucher">
      <file-link-btn
        v-if="details.stop.officialVoucherSource"
        :loading="loadingFileType === 'stopOfficialVoucher'"
        :title="`${$t('permanentDebts.stopVoucher')}.pdf`"
        append-icon
        @click="
          openFile(
            details.stop.officialVoucherSource,
            'stopOfficialVoucher',
            `${$t('permanentDebts.stopVoucher')}.pdf`
          )
        "
      />
      <span v-else>-</span>
    </app-labeled-data>

    <template v-if="permissions.permanentDebtDetails.showSepa">
      <v-row no-gutters class="mt-10">
        <v-col class="subtitle">
          {{ $t('permanentDebts.bankDetails') }}
        </v-col>
      </v-row>
      <app-labeled-data
        text-right
        no-gutters
        label="accountOwner"
        :value="details.sepa.accountOwner"
      />
      <app-labeled-data text-right no-gutters label="iban" :value="details.sepa.iban" />
    </template>
  </v-container>
</template>

<script>
import FileLinkBtn from '@/shared/components/FileLinkBtn';
import OpenFileMixin from '@/mixins/OpenFileMixin';
import { PERIODS_REQUIRING_PERFORMANCE_DAY } from '@/modules/PermanentDebts/statics/timePeriod';

export default {
  name: 'TabDetails',

  components: { FileLinkBtn },

  mixins: [OpenFileMixin],

  props: {
    details: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    showPerformanceDateIndex() {
      return PERIODS_REQUIRING_PERFORMANCE_DAY.includes(this.details.period);
    }
  }
};
</script>
