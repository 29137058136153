export default {
  computed: {
    showActionDialog: {
      get() {
        return !!this.selectedAction.component;
      },
      set() {
        this.setQueryParam(this.queryParam.ACTION, undefined);
      }
    },
    selectedAction: {
      get() {
        return this.getSelectedAction(this.actions || []);
      },
      set(value) {
        this.setQueryParam(this.queryParam.ACTION, value);
      }
    }
  }
};
